import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";

import { ReactComponent as FinCrimeRiskFlag } from "img/icons/fin-crime.svg";
import { ReactComponent as RiskFlag } from "img/icons/flag-icon.svg";
import { ReactComponent as ESGSvg } from "img/icons/esg-icon.svg";
import { ReactComponent as MajorCrimesSvg } from "img/icons/law-icon.svg";
import { ReactComponent as SanctionsSvg } from "img/icons/sanctions-icon.svg";
import { ReactComponent as WatchlistsSvg } from "img/icons/watchlists-icon.svg";
import { ReactComponent as PepsSvg } from "img/icons/state-owned.svg";

import Popover from "components/atoms/Popover";
import { standardColors } from "styles/colors";
import { RiskSummaryTopic } from "api/insights";

import S from "./styles";

export interface RiskSummaryInfoGraphicProps {
  topic: RiskSummaryTopic;
  summary?: string;
  unidentifiedText?: string;
  disclaimer?: boolean; // Default true
  hideRisk?: boolean; // Default false
}

const getIcon = (topic: RiskSummaryTopic) => {
  switch (topic) {
    case RiskSummaryTopic.SANCTIONS:
      return <SanctionsSvg />;
    case RiskSummaryTopic.WATCHLISTS:
      return <WatchlistsSvg />;
    case RiskSummaryTopic.PEPS:
      return <PepsSvg />;
    case RiskSummaryTopic.FINANCIAL_CRIME:
      return <FinCrimeRiskFlag />;
    case RiskSummaryTopic.ESG:
      return <ESGSvg />;
    case RiskSummaryTopic.PROCEEDINGS:
      return <MajorCrimesSvg />;
    default:
      return <RiskFlag />;
  }
};

const getTitle = (topic: RiskSummaryTopic) => {
  switch (topic) {
    case RiskSummaryTopic.SANCTIONS:
      return "Sanctions";
    case RiskSummaryTopic.WATCHLISTS:
      return "Watchlists";
    case RiskSummaryTopic.PEPS:
      return "PEPs";
    case RiskSummaryTopic.FINANCIAL_CRIME:
      return "Financial Crime";
    case RiskSummaryTopic.ESG:
      return "ESG";
    case RiskSummaryTopic.PROCEEDINGS:
      return "Proceedings";
    default:
      return "Other";
  }
};

interface TooltipProps {
  topic: RiskSummaryTopic;
  summary: string;
  disclaimer: boolean;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}

const Tooltip: FC<TooltipProps> = ({
  topic,
  summary,
  disclaimer,
  onMouseEnter,
  onMouseLeave
}) => {
  const getFormattedSummary = (input: string) =>
    input.replaceAll(/\*\*([^*]+)\*\*/g, "<span>$1</span>");

  const isScreening =
    topic === RiskSummaryTopic.SANCTIONS ||
    topic === RiskSummaryTopic.WATCHLISTS ||
    topic === RiskSummaryTopic.PEPS;

  return (
    <S.TooltipContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <S.TooltopBody>
        <S.TooltipIcon>{getIcon(topic)}</S.TooltipIcon>
        <S.TooltipContent>
          <S.TooltipTitle>{getTitle(topic)}</S.TooltipTitle>
          <S.TooltipText
            dangerouslySetInnerHTML={{
              __html: getFormattedSummary(summary ?? "")
            }}
          />
          {disclaimer && (
            <S.DisclaimerContainer>
              <S.DisclaimerStars />
              <S.DisclaimerText>AI-Generated Summary</S.DisclaimerText>
            </S.DisclaimerContainer>
          )}
        </S.TooltipContent>
      </S.TooltopBody>
      {isScreening ? (
        <S.TooltopFooter>
          For more information, review the{" "}
          <Link to="#screening">Screening Section</Link>
        </S.TooltopFooter>
      ) : (
        <S.TooltopFooter>
          For more information, review the Insights section.
        </S.TooltopFooter>
      )}
    </S.TooltipContainer>
  );
};

const RiskSummaryInfoGraphic: FC<RiskSummaryInfoGraphicProps> = ({
  topic,
  disclaimer = true,
  summary,
  unidentifiedText,
  hideRisk = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const closeTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const preventClose = useCallback(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
  }, []);

  const initiateCloseTooltip = useCallback(() => {
    closeTimeoutRef.current = setTimeout(() => setIsOpen(false), 400);
  }, []);

  const riskIdentified = !!summary && !hideRisk;

  const handleMouseEnter = () => {
    if (riskIdentified) {
      setIsHovered(true);
    }

    if (isOpen) {
      preventClose();
    }
  };

  const handleMouseLeave = () => {
    if (riskIdentified) {
      setIsHovered(false);
    }

    if (isOpen) {
      initiateCloseTooltip();
    }
  };

  const handleMouseLeaveTooltip: MouseEventHandler = () => {
    initiateCloseTooltip();
  };

  const content = (
    <S.Container
      hasRisk={riskIdentified}
      active={isHovered || isOpen}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setIsOpen(prev => !prev)}
    >
      <S.IconContainer>{getIcon(topic)}</S.IconContainer>
      <S.Title>{getTitle(topic)}</S.Title>
      {isHovered && riskIdentified ? (
        <S.DetailsLink>See details</S.DetailsLink>
      ) : (
        <S.Text>
          {riskIdentified ? "Identified" : unidentifiedText ?? "None"}
        </S.Text>
      )}
    </S.Container>
  );

  if (!riskIdentified) {
    return content;
  }

  return (
    <Popover
      isOpenOverride={isOpen}
      arrowColor={standardColors.white}
      arrowSize="22px"
      hideArrow
      content={
        <Tooltip
          topic={topic}
          summary={summary}
          disclaimer={disclaimer}
          onMouseEnter={preventClose}
          onMouseLeave={handleMouseLeaveTooltip}
        />
      }
      alignment="bottom"
    >
      {content}
    </Popover>
  );
};

export default RiskSummaryInfoGraphic;
